<template>
  <h4>404 - Page not found</h4>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped></style>
